import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { l_joule } from "../../locales";
import JouleTypography from "../theme/JouleTypography";

const Findings = () => {
  return (
    <div className="mx-auto max-w-[1100px]">
      <JouleTypography variant="body" color="black" className="mb-6 font-bold">
        {l_joule("goalsSection.findings.question")}
      </JouleTypography>
      <MabiParallax
        id="joule_findings"
        tweens={[
          {
            selector: "#finding_1",
            delay: 0,
            from: {
              opacity: 0,
              y: 80,
            },
            to: {
              opacity: 1,
              y: 0,
            },
          },
          {
            selector: "#finding_2",
            delay: 0.3,
            from: {
              opacity: 0,
              y: 80,
            },
            to: {
              opacity: 1,
              y: 0,
            },
          },
          {
            selector: "#finding_3",
            delay: 0.6,
            from: {
              opacity: 0,
              y: 80,
            },
            to: {
              opacity: 1,
              y: 0,
            },
          },
        ]}
      >
        <div className="relative flex w-full flex-col gap-10 md:gap-20">
          <div className="w-full" id="finding_1">
            <JouleTypography variant="section-title" className="">
              {l_joule("goalsSection.findings.description1")}
            </JouleTypography>
          </div>
          <div className="w-full" id="finding_2">
            <JouleTypography variant="section-title" className="">
              {l_joule("goalsSection.findings.description2")}
            </JouleTypography>
          </div>
          <div id="finding_3" className="w-full">
            <JouleTypography variant="section-title" color="joule_dark">
              {l_joule("goalsSection.findings.description3")}
            </JouleTypography>
          </div>
        </div>
      </MabiParallax>
    </div>
  );
};

export default Findings;
