import { Box, useMediaQuery, useTheme } from "@mui/material";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
//@ts-ignore
import photo1 from "../../images/photo_1.png";
//@ts-ignore
import photo2 from "../../images/photo_2.jpg";
//@ts-ignore
import photo3 from "../../images/photo_3.jpg";

const PhotoSection = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <>
      <div className="-mt-8 w-full pb-10 md:-mt-12 md:pb-20">
        <div className="mx-auto max-w-[1300px] px-6">
          <MabiParallax
            id="joule_photo_section"
            tweens={[
              {
                selector: "#joule_photo_1",
                delay: 0.3,
                from: {
                  backgroundPosition: "50% 50%",
                  backgroundSize: "auto 115%",
                },
                to: {
                  backgroundPosition: "60% 40%",
                  backgroundSize: "auto 100%",
                },
              },
              {
                selector: "#joule_photo_3",
                delay: 1.1,
                from: {
                  backgroundPosition: "60% 40%",
                  backgroundSize: "auto 110%",
                },
                to: {
                  backgroundPosition: "65% 50%",
                  backgroundSize: "auto 100%",
                },
              },
              {
                selector: "#joule_photo_2",
                delay: 0.8,
                from: {
                  backgroundPosition: "40% 60%",
                  backgroundSize: "auto 120%",
                },
                to: {
                  backgroundPosition: "50% 50%",
                  backgroundSize: "auto 100%",
                },
              },
            ]}
          >
            <div className="flex w-full items-center justify-between gap-6 md:gap-12">
              <div className="flex w-full flex-shrink-0 basis-full flex-col items-end justify-start gap-6 sm:basis-1/2 md:gap-12">
                <Box
                  id="joule_photo_1"
                  className="mr-auto sm:mr-0"
                  sx={{
                    backgroundImage: `url(${photo1})`,
                    width: { sm: "100%", xs: "80%" },
                    aspectRatio: "8/6",
                    borderRadius: "32px",
                    backgroundSize: "auto 115%",
                  }}
                />
                <Box
                  id="joule_photo_3"
                  sx={{
                    backgroundImage: `url(${photo3})`,
                    width: { sm: "80%", xs: "70%" },
                    aspectRatio: "6/8",
                    borderRadius: "32px",
                    backgroundSize: "200% 120%",
                  }}
                />
              </div>
              {isSm && (
                <Box
                  id="joule_photo_2"
                  style={{
                    backgroundImage: `url(${photo2})`,
                    width: "100%",
                    aspectRatio: "8/6",
                    borderRadius: "32px",
                    backgroundPosition: "50% 50%",
                    backgroundSize: "auto 115%",
                  }}
                />
              )}
            </div>
          </MabiParallax>
        </div>
      </div>
    </>
  );
};

export default PhotoSection;
