import React from "react";
import { animated, easings, useSpring } from "react-spring";

const SquaresAnimation = () => {
  const [squareLT] = useSpring(() => {
    return {
      from: {
        transform: "rotate(45deg) scale(1)",
        top: "0%",
        left: "0%",
        translateX: "0%",
        translateY: "0%",
        borderRadius: "0%",
      },
      to: [
        {
          transform: "rotate(135deg) scale(1)",
          top: "25%",
          left: "50%",
          translateX: "50%",
          translateY: "25%",
        },
        {
          transform: "rotate(135deg) scale(1.5)",
          borderRadius: "50%",
          top: "22%",
          left: "60%",
        },
        {
          transform: "rotate(45deg) scale(1)",
          top: "0%",
          left: "0%",
          translateX: "0%",
          translateY: "0%",
          borderRadius: "0%",
        },
      ],
      config: {
        duration: 2000,
        easing: easings.easeInOutCirc,
      },
      loop: true,
    };
  });

  const [squareRT] = useSpring(() => {
    return {
      from: {
        transform: "rotate(45deg) scale(1)",
        top: "0%",
        left: "65%",
        translateX: "0%",
        translateY: "0%",
        borderRadius: "0%",
      },
      to: [
        {
          transform: "rotate(135deg) scale(1)",
          top: "25%",
          left: "50%",
          translateX: "50%",
          translateY: "25%",
        },
        {
          transform: "rotate(135deg) scale(1.5)",
          borderRadius: "10%",
          top: "22%",
          left: "60%",
        },
        {
          transform: "rotate(45deg) scale(1)",
          top: "0%",
          left: "65%",
          translateX: "0%",
          translateY: "0%",
          borderRadius: "0%",
        },
      ],
      config: {
        duration: 2000,
        easing: easings.easeInOutCirc,
      },
      loop: true,
    };
  });

  const [squareLB] = useSpring(() => {
    return {
      from: {
        transform: "rotate(45deg) scale(1)",
        top: "65%",
        left: "0%",
        translateX: "0%",
        translateY: "0%",
        borderRadius: "0%",
      },
      to: [
        {
          transform: "rotate(135deg) scale(1)",
          top: "25%",
          left: "50%",
          translateX: "50%",
          translateY: "25%",
        },
        {
          transform: "rotate(135deg) scale(0.9)",
          top: "26%",
          left: "49%",
          borderRadius: "50%",
        },
        {
          transform: "rotate(45deg) scale(1)",
          top: "65%",
          left: "0%",
          translateX: "0%",
          translateY: "0%",
          borderRadius: "0%",
        },
      ],
      config: {
        duration: 2000,
        easing: easings.easeInOutCirc,
      },
      loop: true,
    };
  });
  const [squareRB] = useSpring(() => {
    return {
      from: {
        transform: "rotate(45deg) scale(1)",
        top: "65%",
        left: "65%",
        translateX: "0%",
        translateY: "0%",
        borderRadius: "0%",
      },
      to: [
        {
          transform: "rotate(135deg) scale(1)",
          top: "25%",
          left: "50%",
          translateX: "50%",
          translateY: "25%",
        },
        {
          transform: "rotate(135deg) scale(1.5)",
          borderRadius: "50%",
          top: "22%",
          left: "60%",
        },
        {
          transform: "rotate(45deg) scale(1)",
          top: "65%",
          left: "65%",
          translateX: "0%",
          translateY: "0%",
          borderRadius: "0%",
        },
      ],
      config: {
        duration: 2000,
        easing: easings.easeInOutCirc,
      },
      loop: true,
    };
  });

  return (
    <div className="relative m-6 aspect-square max-h-full w-full max-w-[250px]">
      <animated.div
        className={
          "absolute left-0 top-0 z-20 aspect-square w-[35%] rotate-45 bg-[#DAEAFF]"
        }
        style={squareLT}
      />
      <animated.div
        className={
          "absolute left-[calc(100%-35%)] top-0 z-10 aspect-square w-[35%] rotate-45 bg-[#A6D6FA]"
        }
        style={squareRT}
      />
      <animated.div
        style={squareLB}
        className={
          "absolute left-0 top-[calc(100%-35%)] z-30 aspect-square w-[35%] origin-center rotate-45 bg-[#93C9FA]"
        }
      />

      <animated.div
        style={squareRB}
        className={
          "absolute left-[calc(100%-35%)] top-[calc(100%-35%)] z-20 aspect-square w-[35%] rotate-45 bg-[#DAEAFF]"
        }
      />
    </div>
  );
};

export default SquaresAnimation;
