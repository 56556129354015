/** @jsxImportSource @emotion/react */

import { Typography, TypographyProps } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

type JouleTypographyProps = {
  variant?: "header" | "hero" | "title" | "section-title" | "body" | "span";
  color?:
    | "black"
    | "white"
    | "joule_dark"
    | "joule_light"
    | "joule_medium"
    | "joule_intersection";
  className?: string;
} & Omit<TypographyProps, "variant" | "color">;

const JouleTypography: React.FC<JouleTypographyProps> = ({
  variant,
  color,
  sx,
  ...props
}) => {
  return (
    <Typography
      component={
        variant === "header" || variant === "hero"
          ? "h1"
          : variant === "span"
            ? "span"
            : "p"
      }
      sx={{
        ...(color === "black"
          ? { color: "#000000" }
          : color === "white"
            ? { color: "#FFF" }
            : color === "joule_dark"
              ? { color: "#1F8FFF" }
              : color === "joule_light"
                ? { color: "#DAEAFF" }
                : color === "joule_medium"
                  ? { color: "#93C9FA" }
                  : color === "joule_intersection"
                    ? { color: "#A6D6FA" }
                    : { color: "#000000" }),
        ...(variant === "hero"
          ? {
              fontSize: { xs: "44px", sm: "42px", md: "90px" },
              lineHeight: { xs: "44px", sm: "42px", md: "90px" },
              fontWeight: 700,
              zIndex: 2,
            }
          : variant === "header"
            ? {
                fontSize: { xs: "44px", sm: "42px", md: "64px", lg: "90px" },
                lineHeight: { xs: "44px", sm: "42px", md: "64px", lg: "90px" },
                fontWeight: 700,
                zIndex: 2,
              }
            : variant === "title"
              ? {
                  fontSize: { xs: "18px", sm: "20px", md: "28px" },
                  lineHeight: { xs: "18px", sm: "24px", md: "32px" },
                  fontWeight: 600,
                  zIndex: 2,
                }
              : variant === "section-title"
                ? {
                    fontSize: {
                      lg: "48px",
                      md: "40px",
                      sm: "28px",
                      xs: "20px",
                    },
                    lineHeight: {
                      lg: "52px",
                      md: "45px",
                      sm: "32px",
                      xs: "24px",
                    },
                    fontWeight: 700,
                    zIndex: 2,
                  }
                : variant === "body"
                  ? {
                      fontSize: { xs: "16px", sm: "18px", md: "20px" },
                      lineHeight: { xs: "20px", sm: "24px", md: "28px" },
                      fontWeight: 400,
                      zIndex: 2,
                    }
                  : variant === "span"
                    ? {
                        fontSize: "inherit",
                        lineHeight: "inherit",
                        fontWeight: "inherit",
                        color: "inherit",
                        zIndex: 2,
                      }
                    : {}),

        ...sx,
      }}
      {...props}
    />
  );
};
export default JouleTypography;
