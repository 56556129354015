import React from "react";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { StaticImage } from "gatsby-plugin-image";

const SecondPhoneSection = () => {
  return (
    <div className="flex w-full items-center py-8">
      <div className="relative mx-auto flex min-h-[400px] w-full max-w-[1200px] md:min-h-[600px] lg:min-h-[600px]">
        <MabiParallax
          id="joule_phone_second"
          tweens={[
            {
              selector: "#phone_sec_central",
              delay: 0,
              from: {
                transform: "translateX(-50%) scale(0.9)",
              },
              to: {
                transform: "translateX(-50%) scale(1)",
              },
            },
            {
              selector: "#phone_sec_right",
              delay: 0.4,
              from: {
                transform: "translateX(-50%) scale(0.4)",
              },
              to: {
                transform: "translateX(60%)  scale(1)",
              },
            },
            {
              selector: "#phone_sec_left",
              delay: 0.4,
              from: {
                transform: "translateX(-50%) scale(0.4)",
              },
              to: {
                transform: "translateX(-160%) scale(1)",
              },
            },
          ]}
        >
          <div
            id={"phone_sec_left"}
            className="absolute left-1/2 top-0 z-20 aspect-[0.47] h-full min-h-full overflow-hidden rounded-3xl drop-shadow-2xl"
          >
            <StaticImage
              src={`../../images/phone_sec_left.png`}
              alt=""
              objectFit="cover"
              className="max-h-full max-w-full"
            />
          </div>
          <div
            id={"phone_sec_right"}
            className="absolute left-1/2 top-0 z-20 aspect-[0.47] h-full min-h-full overflow-hidden rounded-3xl drop-shadow-2xl"
          >
            <StaticImage
              src={`../../images/phone_sec_right.png`}
              alt=""
              objectFit="cover"
              className="max-h-full max-w-full"
            />
          </div>
          <div
            id={"phone_sec_central"}
            className="absolute left-1/2 top-0 z-20 aspect-[0.47] h-full min-h-full overflow-hidden rounded-3xl drop-shadow-2xl"
          >
            <StaticImage
              src={`../../images/phone_sec_central.png`}
              alt=""
              objectFit="cover"
              className="max-h-full w-fit"
            />
          </div>
        </MabiParallax>
      </div>
    </div>
  );
};

export default SecondPhoneSection;
