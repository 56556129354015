import { Box, useMediaQuery, useTheme } from "@mui/material";
//@ts-ignore
import photoR from "../../images/guerrilla_r1.jpg";
//@ts-ignore
import photoLT from "../../images/guerrilla_l1.jpg";
//@ts-ignore
import photoLB from "../../images/guerrilla_l2.jpg";
import JouleTypography from "../theme/JouleTypography";
import { l_joule } from "../../locales";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

const Testers = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <MabiParallax
      id="joule_testers_section"
      tweens={[
        {
          selector: "#joule_photo_lt",
          delay: 0.3,
          from: {
            backgroundPosition: "50% 50%",
            backgroundSize: "auto 115%",
          },
          to: {
            backgroundPosition: "60% 40%",
            backgroundSize: "auto 100%",
          },
        },
        {
          selector: "#joule_photo_r",
          delay: 0.4,
          from: {
            backgroundPosition: "60% 40%",
            backgroundSize: "auto 110%",
          },
          to: {
            backgroundPosition: "65% 50%",
            backgroundSize: "auto 100%",
          },
        },
        {
          selector: "#joule_photo_lb",
          delay: 0.5,
          from: {
            backgroundPosition: "50% 60%",
            backgroundSize: "auto 120%",
          },
          to: {
            backgroundPosition: "50% 50%",
            backgroundSize: "auto 100%",
          },
        },
      ]}
    >
      <div className="grid grid-cols-1 gap-6 md:grid-cols-[9fr,10fr]">
        <div className="hidden flex-col items-end gap-6 md:flex">
          <Box
            id="joule_photo_lt"
            className=""
            sx={{
              backgroundImage: `url(${photoLT})`,
              width: { sm: "100%", xs: "80%" },
              aspectRatio: "8/6",
              borderRadius: "32px",
              backgroundSize: "auto 115%",
            }}
          />
          <Box
            id="joule_photo_lb"
            className=""
            sx={{
              backgroundImage: `url(${photoLB})`,
              width: { sm: "80%" },
              aspectRatio: "5/7",
              borderRadius: "32px",
              backgroundSize: "auto 115%",
            }}
          />
        </div>
        <div className="flex flex-col items-start gap-6 md:mt-24">
          <div className="flex w-full gap-6">
            <Box
              id="joule_photo_r"
              className="mb-0 sm:mb-12 md:mb-0"
              sx={{
                backgroundImage: `url(${photoR})`,
                width: { md: "100%", xs: "80%", sm: "60%" },
                aspectRatio: "8/6",
                borderRadius: "32px",
                backgroundSize: "auto 115%",
              }}
            />
            <Box
              id="joule_photo_lb"
              className="mt-12 hidden flex-1 sm:block md:hidden"
              sx={{
                backgroundImage: `url(${photoLB})`,
                aspectRatio: "8/6",
                borderRadius: "32px",
                backgroundSize: "auto 115%",
              }}
            />
          </div>
          <div className="box-border w-[320px] sm:w-[580px] md:w-[460px] lg:w-auto">
            <JouleTypography
              variant="section-title"
              className="flex-1"
              sx={{
                fontSize: {
                  lg: "44px",
                  md: "40px",
                  sm: "28px",
                  xs: "18px",
                },
              }}
            >
              {l_joule("testing.otherTitle1")}
              {!isMd && <br />}
              <JouleTypography variant="span" className="joule_underline">
                {l_joule("testing.otherTitleUnderlined1")}{" "}
              </JouleTypography>
              {l_joule("testing.otherTitle2")} {l_joule("testing.otherTitle3")}
              {isMd && <br />}
              {!isSm && <br />}
              <JouleTypography variant="span" className="joule_underline">
                {l_joule("testing.otherTitleUnderlined2")}
              </JouleTypography>
              {isMd ? <br /> : " "}
              <JouleTypography variant="span">
                {l_joule("testing.otherTitle4")}
              </JouleTypography>
            </JouleTypography>
          </div>
        </div>
      </div>
    </MabiParallax>
  );
};

export default Testers;
