import { DeepKeyof, language } from "../../../traductions";

const traduction = {
  it: {
    seo: {
      title: "Joule",
      description:
        "Joule è la piattaforma digitale che rivoluziona la ricerca lavoro con l’Intelligenza Artificiale. Un’esperienza più umana e autentica grazie a video di presentazione, user testing e design empatico.",
    },
    header: {
      description:
        "Migliorare l’interazione uomo-macchina grazie allo user testing",
    },
    description: {
      topSection: {
        part1: "Joule è un compagno di carriera intelligente che utilizza",
        underlined: "l'Intelligenza Artificiale",
        part2:
          " per rendere il processo di ricerca lavoro più efficace e, soprattutto, più umano.",
      },
      bottomSection: {
        title: "Comprendere le radici del problema",
        description:
          "Joule rivoluziona il processo di selezione, con la consapevolezza che il tradizionale curriculum vitae spesso non trasmette a pieno il valore e la personalità dei candidati. Per questo motivo, la piattaforma ha introdotto i video di presentazione, offrendo un modo più autentico e coinvolgente di esprimere le proprie soft skill e facilitando la connessione tra talenti e recruiter.",
      },
    },
    questionSection: {
      smallText: "II team di Joule ci ha chiesto:",
      question:
        "“Come possiamo incentivare gli utenti a registrare video di presentazione su Joule?”",
      mediumText1: "Per trovare la risposta a questa domanda abbiamo svolto ",
      mediumTextUnderlined: "cinque sessioni di interviste",
      mediumText2:
        " con gli utenti, ciascuna della durata di un'ora, che ci hanno fornito risposte fondamentali.",
    },
    goalsSection: {
      findings: {
        question: "Cosa abbiamo scoperto?",
        description1:
          "Nonostante Joule abbia sviluppato un sistema per efficientare la ricerca lavoro e l’abbia resa più umana, abbiamo riscontrato che alcuni utenti nutrivano incertezze riguardo all’utilizzo dell’IA e faticavano a comprenderne i benefici.",
        description2:
          "I dati raccolti dimostravano che solo il 20% degli intervistati provava sentimenti positivi nei confronti dell’IA in generale.",
        description3:
          "Poiché Joule trova nell’IA il proprio tratto distintivo, abbiamo compreso che avremmo dovuto rendere i vantaggi di questo approccio evidenti anche per gli utenti che partivano prevenuti nei confronti dell’IA.",
      },
      goals: {
        question1: "Costruire la fiducia:",
        question2: "prototipazione e usability testing",
        description1: "Ci siamo posti un obiettivo ambizioso:",
        description2: "rafforzare la ",
        descriptionUnderlined: "fiducia nell'IA",
        description3:
          " di Joule e rendere la registrazione dei video un'esperienza più confortevole e coinvolgente.",
      },
    },
    testing: {
      title: "Non solo testing",
      description:
        "Per testare i nostri prototipi di app abbiamo adottato un approccio di guerrilla testing. Come suggerisce il nome, si tratta di un processo veloce e divertente che ha l’obiettivo di raccogliere una grande quantità di dati sull’usabilità di un prodotto digitale in pubblico, in poco tempo. In più, abbiamo offerto un reward impossibile da rifiutare!",
      otherTitle1: "Grazie al contributo di più di ",
      otherTitleUnderlined1: "70 tester",
      otherTitle2: "abbiamo potuto validare le modifiche che avevamo proposto,",
      otherTitle3: "dimostrando che la nostra visione poteva effettivamente ",
      otherTitleUnderlined2: "migliorare l'esperienza",
      otherTitle4: " dell'utente.",
    },
    toneOfVoice: {
      titleTop: "Tone of Voice e percezione",
      descriptionTop:
        "Abbiamo sperimentato con il Tone of Voice visivo, passando dalle illustrazioni alle fotografie per aumentare la percezione di sicurezza e umanità tra gli utenti. Il risultato è stato un successo.",
      titleBottom: "Tirata a lucido",
      descriptionBottom:
        "Abbiamo finalizzato le modifiche nel design dell’app, passando da illustrazioni a foto e facendo particolare attenzione al copywriting: abbiamo testato nuovamente il prototipo con circa 35 utenti online utilizzando la piattaforma Maze. I feedback sono stati nettamente più positivi rispetto ai primi test effettuati.",
    },
    empathy: {
      title: "L’empatia come chiave per interpretare i dati",
      description:
        "Il cuore di questo progetto è stato ascoltare attentamente gli utenti, comprendere le loro preoccupazioni e offrire soluzioni che migliorassero la loro esperienza con Joule. Un approccio di ricerca qualitativa ha guidato ogni passo, confermando che dar voce agli utenti è il miglior strumento per comprendere come rendere l’esperienza utente più gratificante.",
    },
  },
  en: {
    seo: {
      title: "Joule",
      description:
        "Joule is the digital platform revolutionizing job hunting with Artificial Intelligence. A more human and authentic experience through video presentations, user testing, and empathetic design.",
    },
    header: {
      description: "Improving human-machine interaction thanks to user testing",
    },
    description: {
      topSection: {
        part1: "Joule is a smart career companion using",
        underlined: "Artificial Intelligence",
        part2:
          " to make the job hunting process more efficient and, above all, more human.",
      },
      bottomSection: {
        title: "Understanding the roots of the problem",
        description:
          "Joule revolutionizes the selection process, with the awareness that the traditional curriculum vitae often does not fully convey the value and personality of the candidates. For this reason, the platform introduced presentation videos, offering a more authentic and engaging way to express one's soft skills and facilitating the connection between talents and recruiters.",
      },
    },
    questionSection: {
      smallText: "Joule's team asked us:",
      question:
        "“How can we encourage users to record presentation videos on Joule?”",
      mediumText1: "To find an answer to this question we did ",
      mediumTextUnderlined: "five rounds of interviews",
      mediumText2:
        " with users, each one of the duration of a hour, and this gave us fundamental answers.",
    },
    goalsSection: {
      findings: {
        question: "What did we discover?",
        description1:
          "Although Joule has developed a system to streamline job searches and make them more human, we found that some users were uncertain about using AI and struggled to understand its benefits.",
        description2:
          "The data collected showed that only 20% of respondents had positive feelings towards AI in general.",
        description3:
          "Since Joule finds its distinctive trait in AI, we understood that we would have to make the advantages of this approach evident even for users who started out prejudiced against AI.",
      },
      goals: {
        question1: "Building trust:",
        question2: "prototyping and usability testing",
        description1: "We had an ambitious goal:",
        description2: "increase the ",
        descriptionUnderlined: "trust in the AI",
        description3:
          " of Joule and make the video recording more comfortable and captivating.",
      },
    },
    testing: {
      title: "Not simply testing",
      description:
        "We took a guerrilla testing approach to test our app prototypes. As the name suggests, it's a fast and fun process that aims to collect a large amount of data on the usability of a digital product in a short time, in a public setting. Plus, we offered a reward that was impossible to refuse!",
      otherTitle1: "With the contribution of more than ",
      otherTitleUnderlined1: "70 testers",
      otherTitle2: "we were able to validate our proposal,",
      otherTitle3: "showing that our vision could lead to a ",
      otherTitleUnderlined2: "better experience",
      otherTitle4: " for the user.",
    },
    toneOfVoice: {
      titleTop: "Tone of Voice and perception",
      descriptionTop:
        "We experimented with visual Tone of Voice, moving from illustrations to photographs to increase the perception of safety and humanity among users. The result was a success.",
      titleBottom: "Glammed up",
      descriptionBottom:
        "We finalized the changes in the app design, moving from illustrations to photos and paying particular attention to the copywriting: we tested the prototype again with about 35 online users using the Maze platform. The feedback was significantly more positive than the first tests.",
    },
    empathy: {
      title: "Empathy as a key data interpretation",
      description:
        "The heart of this project was to listen carefully to users, understand their concerns and offer solutions that improve their experience with Joule. A qualitative research approach guided every step, confirming that giving users a voice is the best tool for understanding how to make the user experience more rewarding.",
    },
  },
};

export const l_joule = (
  trad: DeepKeyof<(typeof traduction)[keyof typeof traduction]>,
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
