import React from "react";
import Findings from "./Findings";
import Goal from "./Goal";

const GoalsSection = () => {
  return (
    <div className="m-auto mx-auto box-border w-full px-4 md:px-6">
      <Findings />
      <Goal />
    </div>
  );
};

export default GoalsSection;
