import { useMediaQuery, useTheme } from "@mui/material";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
// @ts-ignore
import scroll1 from "../../images/scroll_phone_1.png";
// @ts-ignore
import scroll2 from "../../images/scroll_phone_2.png";
// @ts-ignore
import scroll3 from "../../images/scroll_phone_3.png";
// @ts-ignore
import scroll4 from "../../images/scroll_phone_4.png";
// @ts-ignore
import scroll5 from "../../images/scroll_phone_5.png";
// @ts-ignore
import scroll6 from "../../images/scroll_phone_6.png";

const PhonesAnimation = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <MabiParallax
      id="joule_phones_scroll_animation"
      tweens={[
        {
          selector: "#photos_container",
          delay: isMd ? 0 : 0.3,
          from: {
            x: isMd ? "-100%" : "150%",
          },
          to: {
            x: isMd ? "0%" : 0,
          },
        },
        ...(isMd
          ? [
              {
                selector: "#phone_scroll_1",
                from: {
                  // opacity: 0,
                  x: "-180%",
                },
                to: {
                  opacity: 1,
                  x: 0,
                },
              },
              {
                selector: "#phone_scroll_2",
                from: {
                  // opacity: 0,
                  x: "-150%",
                },
                to: {
                  opacity: 1,
                  x: 0,
                },
              },
              {
                selector: "#phone_scroll_3",
                from: {
                  // opacity: 0,
                  x: "-120%",
                },
                to: {
                  opacity: 1,
                  x: 0,
                },
              },
              {
                selector: "#phone_scroll_4",
                from: {
                  // opacity: 0,
                  x: "-90%",
                },
                to: {
                  opacity: 1,
                  x: 0,
                },
              },
              {
                selector: "#phone_scroll_5",
                from: {
                  // opacity: 0,
                  x: "-60%",
                },
                to: {
                  opacity: 1,
                  x: 0,
                },
              },
              {
                selector: "#phone_scroll_6",
                from: {
                  // opacity: 0,
                  x: "-30%",
                },
                to: {
                  opacity: 1,
                  x: 0,
                },
              },
            ]
          : []),
      ]}
    >
      <div className="relative h-full min-h-[400px] w-full">
        <div
          id="photos_container"
          className="absolute right-0 top-0 flex h-full min-h-[400px] w-full flex-row-reverse justify-start gap-4"
        >
          <div
            id={"phone_scroll_1"}
            className="aspect-[0.47] h-full max-h-full rounded-2xl drop-shadow-2xl"
            style={{
              backgroundImage: `url(${scroll1})`,
              height: "100%",
              aspectRatio: "0.47",
              backgroundSize: "cover",
            }}
          />
          <div
            id={"phone_scroll_2"}
            className="aspect-[0.47] h-full max-h-full rounded-2xl drop-shadow-2xl"
            style={{
              backgroundImage: `url(${scroll2})`,
              height: "100%",
              aspectRatio: "0.47",
              backgroundSize: "cover",
            }}
          />
          <div
            id={"phone_scroll_3"}
            className="aspect-[0.47] h-full max-h-full rounded-2xl drop-shadow-2xl"
            style={{
              backgroundImage: `url(${scroll3})`,
              height: "100%",
              aspectRatio: "0.47",
              backgroundSize: "cover",
            }}
          />
          <div
            id={"phone_scroll_4"}
            className="aspect-[0.47] h-full max-h-full rounded-2xl drop-shadow-2xl"
            style={{
              backgroundImage: `url(${scroll4})`,
              height: "100%",
              aspectRatio: "0.47",
              backgroundSize: "cover",
            }}
          />
          <div
            id={"phone_scroll_5"}
            className="aspect-[0.47] h-full max-h-full rounded-2xl drop-shadow-2xl"
            style={{
              backgroundImage: `url(${scroll5})`,
              height: "100%",
              aspectRatio: "0.47",
              backgroundSize: "cover",
            }}
          />
          <div
            id={"phone_scroll_6"}
            className="aspect-[0.47] h-full max-h-full rounded-2xl drop-shadow-2xl"
            style={{
              backgroundImage: `url(${scroll6})`,
              height: "100%",
              aspectRatio: "0.47",
              backgroundSize: "cover",
            }}
          />
        </div>
      </div>
    </MabiParallax>
  );
};

export default PhonesAnimation;
