import React from "react";
import JouleTypography from "../theme/JouleTypography";
import { l_joule } from "../../locales";
import HeaderAnimations from "./HeaderAnimations";
import useResizeObserver from "use-resize-observer";

const Header = () => {
  const { ref, height } = useResizeObserver({
    box: "border-box",
  });
  return (
    <div
      ref={ref}
      className="relative box-border flex max-w-full items-center justify-center bg-[#1F8FFF] px-4 pb-4 pt-8 sm:pb-8 sm:pt-12 md:px-6 md:pb-12 md:pt-28"
    >
      <div className="flex min-h-[180px] w-full max-w-[1100px] flex-col justify-between gap-10 sm:flex-row sm:items-end sm:gap-2">
        <JouleTypography variant="hero" color="white" className="">
          {l_joule("seo.title")}
        </JouleTypography>
        <JouleTypography
          variant={"title"}
          className="leading-8 sm:max-w-[320px]"
          color="white"
        >
          {l_joule("header.description")}
        </JouleTypography>
        <HeaderAnimations parentHeight={height ?? 0} />
      </div>
    </div>
  );
};

export default Header;
