import { animated, easings, useSpring } from "react-spring";
import { l_joule } from "../../locales";
import JouleTypography from "../theme/JouleTypography";
import { transformer } from "zod";
import SquaresAnimation from "./SquaresAnimation";

const BottomSection = () => {
  return (
    <div className="mt-10 flex w-full flex-col justify-between gap-10 sm:flex-row sm:gap-4 md:mt-20">
      <div className="flex max-h-full w-[50%] flex-shrink-0 items-center justify-start">
        <SquaresAnimation />
      </div>

      <div className="flex-1">
        <JouleTypography variant="section-title" className="flex-1">
          {l_joule("description.bottomSection.title")}
        </JouleTypography>
        <JouleTypography variant="body" className="mt-4 md:mt-6">
          {l_joule("description.bottomSection.description")}
        </JouleTypography>
      </div>
    </div>
  );
};

export default BottomSection;
