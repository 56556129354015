import React from "react";
import TopSection from "./TopSection";
import BottomSection from "./BottomSection";

const Description = () => {
  return (
    <div className="mx-auto mt-10 max-w-[1100px] px-8">
      <TopSection />
      <BottomSection />
    </div>
  );
};

export default Description;
