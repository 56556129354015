import { l_joule } from "../../locales";
import JouleTypography from "../theme/JouleTypography";
import PhonesAnimation from "./PhonesAnimation";

const Polished = () => {
  return (
    <div className="mx-auto box-border flex w-full max-w-[1100px] flex-col-reverse items-center gap-10 md:flex-row md:px-6 md:pl-0">
      <div className="mr-6 h-full w-full min-w-0 flex-1 flex-shrink md:mr-0">
        <PhonesAnimation />
      </div>
      <div className="box-border flex-1 px-6 md:max-w-[350px] md:px-0">
        <JouleTypography variant="section-title" className="">
          {l_joule("toneOfVoice.titleBottom")}
        </JouleTypography>
        <JouleTypography variant="body" className="mt-4 md:mt-6">
          {l_joule("toneOfVoice.descriptionBottom")}
        </JouleTypography>
      </div>
    </div>
  );
};

export default Polished;
