import { l_joule } from "../../locales";
import JouleTypography from "../theme/JouleTypography";

const Goal = () => {
  return (
    <div className="mx-auto mb-4 mt-24 flex w-full max-w-[1300px] flex-col gap-6 md:mb-8 md:mt-44 md:flex-row md:gap-12">
      <div className="min-w-[330px]">
        <JouleTypography variant="body" className="font-bold">
          {l_joule("goalsSection.goals.question1")}
          <br />
          {l_joule("goalsSection.goals.question2")}
        </JouleTypography>
      </div>
      <div>
        <JouleTypography variant="section-title" className="flex-shrink">
          {l_joule("goalsSection.goals.description1")} <br />
          {l_joule("goalsSection.goals.description2")}
          <JouleTypography variant="span" className="joule_underline">
            {l_joule("goalsSection.goals.descriptionUnderlined")}
          </JouleTypography>
          {l_joule("goalsSection.goals.description3")}
        </JouleTypography>
      </div>
    </div>
  );
};

export default Goal;
