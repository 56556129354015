import React from "react";
import JouleTypography from "../theme/JouleTypography";
import { l_joule } from "../../locales";
import { animated, useSpring } from "react-spring";

const QuestionSection = () => {
  const [style] = useSpring(() => {
    return {
      from: {
        background: "linear-gradient(90deg, #FFFFFF, #93C9FA)",
        backgroundClip: "text",
      },
      to: {
        background: "linear-gradient(90deg, #93C9FA, #FFFFFF)",
      },
      config: {
        duration: 2500,
      },
      loop: { reverse: true, delay: 0 },
    };
  });

  return (
    <div className="mt-10 flex max-w-full items-center justify-center rounded-3xl bg-[#1F8FFF] px-4 pb-16 pt-8 sm:pb-20 sm:pt-12 md:mt-20 md:px-6 md:pb-24 md:pt-12">
      <div className="min-h-[180px] w-full max-w-[1100px] justify-between gap-10 sm:gap-2">
        <JouleTypography variant="body" color="white">
          {l_joule("questionSection.smallText")}
        </JouleTypography>
        <animated.div
          className="mb-10 mt-8 bg-clip-text md:mb-24 md:mt-12"
          style={style}
        >
          <JouleTypography variant="header" className="text-transparent">
            {l_joule("questionSection.question")}
          </JouleTypography>
        </animated.div>
        <JouleTypography variant="section-title" color="white">
          {l_joule("questionSection.mediumText1")}
          <br />
          <JouleTypography variant="span" className="joule_underline_white">
            {l_joule("questionSection.mediumTextUnderlined")}
          </JouleTypography>
          {l_joule("questionSection.mediumText2")}
        </JouleTypography>
      </div>
    </div>
  );
};

export default QuestionSection;
