import React from "react";
import ToneAndPerception from "./ToneAndPerception";
import Polished from "./Polished";

const ToneOfVoice = () => {
  return (
    <div className="mt-10 flex w-full flex-col justify-between gap-10 md:mt-20 md:gap-16">
      <ToneAndPerception />
      <Polished />
    </div>
  );
};

export default ToneOfVoice;
