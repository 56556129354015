import { animated, easings, useSpring } from "react-spring";
import gsap, { Bounce, Circ, Elastic, Power3 } from "gsap";

// @ts-ignore
import joule_logo from "../../images/logo_joule.jpeg";
// @ts-ignore
import logo_first from "../../images/logo-j.png";
// @ts-ignore
import logo_second from "../../images/logo-j-2.png";
import useResizeObserver from "use-resize-observer";

import { Box } from "@mui/material";
import { useEffect } from "react";

const AnimatedBox = animated(Box);

const LogoAnimation = () => {
  const { ref, width, height } = useResizeObserver();

  useEffect(() => {
    let tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.5,
    });
    tl.fromTo(
      "#container-circle",
      {
        transform: "translate(-50%, -50%) scale(0.8)",
      },
      {
        transform: "translate(-50%, -50%) scale(1)",
        duration: 1,
        ease: Power3.easeInOut,
      },
    )
      .fromTo(
        "#logo-first",
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.5,
          ease: Power3.easeInOut,
        },
        ">0",
      )
      .fromTo(
        "#logo-second",
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.5,
          ease: Power3.easeInOut,
        },
        ">0",
      )
      .fromTo(
        "#blue-bubble",
        {
          transform: "translate(-25%, -25%) scale(0)",
          opacity: 1,
        },
        {
          transform: "translate(-25%, -25%) scale(1)",
          opacity: 1,
          duration: 0.5,
          ease: Circ.easeOut,
        },
        ">0.2",
      )
      .fromTo(
        "#decoration-parent",
        {
          transform: "translate(-50%, -50%) scale(0) rotate(0deg)",
        },
        {
          transform: "translate(-50%, -50%) scale(1) rotate(80deg)",
          duration: 0.5,
          ease: Circ.easeOut,
        },
        ">0.7",
      )
      .fromTo(
        "#deco-1",
        {
          transform: "translate(-30%, -50%) rotate(80deg)",
        },
        {
          transform: "translate(0%, 0%) rotate(10deg)",
          duration: 0.5,
          ease: Circ.easeOut,
        },
        "<0",
      )
      .fromTo(
        "#deco-2",
        {
          transform: "translate(20%, 0%)",
        },
        {
          transform: "translate(0%, -50%)",
          duration: 0.5,
          ease: Circ.easeOut,
        },
        "<0",
      )
      .fromTo(
        "#deco-3",
        {
          transform: "translate(40%, 50%) rotate(60deg)",
        },
        {
          transform: "translate(0%, 0%) rotate(30deg)",
          duration: 0.5,
          ease: Circ.easeOut,
        },
        "<0",
      )
      .to(
        "#decoration-parent",
        {
          transform: "translate(-50%, -50%) rotate(170deg) scale(0)",
          duration: 2,
          ease: Circ.easeOut,
        },
        ">1.2",
      )
      .to(
        "#container-circle",
        {
          transform: "translate(-50%, -50%) scale(0.8)",
          duration: 2,
          ease: Circ.easeOut,
        },
        "<0",
      )
      .to(
        "#logo-first",
        {
          opacity: 0,
          duration: 0.8,
          ease: Circ.easeOut,
        },
        "<0",
      )
      .to(
        "#logo-second",
        {
          opacity: 0,
          duration: 0.8,
          ease: Circ.easeOut,
        },
        "<0",
      )
      .to(
        "#blue-bubble",
        {
          opacity: 0,
          duration: 0.8,
          ease: Circ.easeOut,
        },
        "<0",
      );

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div className="relative h-full w-full" ref={ref}>
      <div
        className={"absolute right-0 top-1/2 -translate-y-1/2"}
        style={{
          // background: "teal",
          // border: "1px solid red",
          aspectRatio: "1/1",
          ...((width ?? 0) >= (height ?? 0)
            ? { maxHeight: "100%", height: "100%" }
            : {
                width: "100%",
                maxWidth: "100%",
              }),
        }}
      >
        <div
          id="container-circle"
          className="absolute left-1/2 top-1/2 z-10 aspect-square w-1/2 origin-center -translate-x-1/2 -translate-y-1/2 rounded-full bg-white"
        >
          <Box
            id="logo-first"
            className="absolute left-0 top-0 h-full w-full opacity-0"
            sx={{
              backgroundImage: `url(${logo_first})`,
              aspectRatio: "2307/2709",
              backgroundSize: "50% auto",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
          <Box
            id="logo-second"
            className="absolute left-0 top-0 h-full w-full opacity-0"
            sx={{
              backgroundImage: `url(${logo_second})`,
              aspectRatio: "2307/2709",
              backgroundSize: "50% auto",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
          <div
            id="blue-bubble"
            className="absolute left-1/4 top-1/3 aspect-square w-[18%] origin-center -translate-x-1/4 -translate-y-1/4 rounded-full bg-blue-500"
          />
        </div>
        <div
          id="decoration-parent"
          className="absolute left-1/2 top-1/2 aspect-square w-[75%] -translate-x-1/2 -translate-y-1/2 rounded-full"
        >
          <div
            id="deco-1"
            className="absolute right-0 top-0 h-[20%] w-[20%] rotate-[10deg] bg-[#1F8FFF]"
          />
          <div
            id="deco-2"
            className="absolute left-1/3 top-0 h-[15%] w-[15%] -translate-y-1/2 rounded-full bg-[#FFF]"
          />
          <div
            id="deco-3"
            className="absolute left-full top-1/2 h-[15%] w-[15%] origin-center rotate-[30deg] rounded-md bg-[#A6D6FA]"
          />
          <div
            id="deco-4"
            className="absolute bottom-0 left-0 h-[20%] w-[20%] bg-[#A6D6FA]"
          />
          <div
            id="deco-5"
            className="absolute left-0 top-[10%] h-[17%] w-[17%] rounded-md bg-[#1F8FFF]"
          />
          <div
            id="deco-6"
            className="absolute bottom-0 right-0 h-[12%] w-[12%] rounded-full bg-[#DAEAFF]"
          />
          <div
            id="deco-7"
            className="absolute left-0 top-1/2 h-[10%] w-[10%] rounded-full bg-[#DAEAFF]"
          />
        </div>
        <div />
      </div>
    </div>
  );
};

export default LogoAnimation;
