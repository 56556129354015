import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { animated, easings, useSpring } from "react-spring";

const HeaderAnimations = ({ parentHeight }: { parentHeight: number }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  const h1Height = isMd ? 90 : isSm ? 42 : 44;

  const [circle1] = useSpring(() => {
    return {
      from: { right: "0%" },
      to: { right: "35%" },
      config: {
        duration: 3000,
        easing: easings.easeInOutCirc,
      },

      loop: { reverse: true, delay: 1000 },
    };
  }, []);

  const [circle2] = useSpring(() => {
    return {
      from: { right: "35%" },
      to: { right: "0%" },
      config: {
        duration: 3000,

        easing: easings.easeInOutCirc,
      },

      loop: { reverse: true, delay: 1000 },
    };
  }, []);

  const [littleSquare, littleSquareApi] = useSpring(() => {
    return {
      from: {
        transform: "scale(1)",
        x: 0,
        y: 0,
        rotate: 0,
        backgroundColor: "#A6D6FA",
        borderRadius: "0%",
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
      loop: true,
    };
  });

  const triggerLittleSquare = () => {
    littleSquareApi.start({
      to: [
        {
          transform: "scale(1)",
          rotate: 90,
          ...(isMd
            ? { y: parentHeight - 32 - 48 - 48 - h1Height }
            : { y: parentHeight - 24 - 48 - 48 - h1Height }),
          backgroundColor: "#A6D6FA",
          x: 0,
          delay: 1000,
          borderRadius: "0%",
        },
        {
          transform: "scale(1)",
          rotate: 0,
          ...(isMd
            ? { y: parentHeight - 32 - 48 - 48 - h1Height }
            : { y: parentHeight - 24 - 48 - 48 - h1Height }),
          backgroundColor: "#9BE7FF",
          x: isMd ? 280 : 120,
          delay: 1000,
          borderRadius: "0%",
        },
        {
          transform: "scale(0.8)",
          rotate: 0,
          ...(isMd
            ? { y: parentHeight - 24 - 48 - 60 }
            : { y: parentHeight - 24 - 48 - 30 }),
          backgroundColor: "#9BE7FF",
          x: isMd ? 280 : 120,
          borderRadius: "50%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
        {
          transform: "scale(2)",
          rotate: 180,
          y: 16,
          backgroundColor: "#9BE7FF",
          x: isMd ? 280 : 120,
          borderRadius: "50%",
          config: {
            duration: 1000,
          },
        },
        {
          transform: "scale(1)",
          x: 0,
          y: 0,
          rotate: 0,
          backgroundColor: "#A6D6FA",
          borderRadius: "0%",
          delay: 1000,
          config: {
            duration: 2000,
          },
        },
      ],
      loop: true,
    });
  };

  const [littleSquareXs, littleSquareXsApi] = useSpring(() => {
    return {
      from: {
        transform: "scale(1)",
        x: 0,
        y: h1Height + 24,
        rotate: 0,
        backgroundColor: "#A6D6FA",
        borderRadius: "0%",
      },
      config: {
        duration: 1000,
        easing: easings.easeInOutCirc,
      },
      loop: true,
    };
  });

  const triggerLittleSquareXs = () => {
    littleSquareXsApi.start({
      to: [
        {
          transform: "scale(1)",
          rotate: 90,
          y: h1Height + 24,
          backgroundColor: "#DAEAFF",
          x: 120,
          delay: 1000,
          borderRadius: "0%",
        },
        {
          transform: "scale(0.8)",
          rotate: 90,
          y: 0,
          backgroundColor: "#9BE7FF",
          x: 120,
          delay: 500,
          borderRadius: "50%",
        },
        {
          transform: "scale(3)",
          rotate: 90,
          y: 30,
          backgroundColor: "#9BE7FF",
          x: 36,
          delay: 1000,
          borderRadius: "0%",
        },
        {
          transform: "scale(1)",
          x: 0,
          y: h1Height + 24,
          rotate: 0,
          backgroundColor: "#A6D6FA",
          borderRadius: "0%",
        },
      ],
      loop: true,
    });
  };

  useEffect(() => {
    littleSquareApi.stop();
    littleSquareXsApi.stop();
    if (isSm) {
      triggerLittleSquare();
    } else {
      triggerLittleSquareXs();
    }
  }, [parentHeight, isSm, isMd, h1Height]);

  return (
    <>
      {/* <div className="absolute left-0 top-0 box-border h-full w-full"></div> */}
      <div className="absolute left-1/2 top-0 box-border h-full w-full max-w-[1100px] -translate-x-1/2">
        <div className="absolute right-6 top-6 aspect-[2] h-[40%] md:right-6">
          <animated.div
            className="absolute right-0 top-0 aspect-square h-[100%] rounded-full bg-[rgba(211,231,255,0.8)]"
            style={circle1}
          />
          <animated.div
            style={circle2}
            className="absolute right-0 top-0 aspect-square h-[100%] rounded-full bg-[#7cc6f1] mix-blend-screen"
          />
        </div>
        {isSm ? (
          <animated.div
            style={littleSquare}
            className={
              "absolute left-4 top-6 aspect-square w-[48px] bg-[#A6D6FA] sm:top-8 md:left-4 xl:left-0"
            }
          />
        ) : (
          <animated.div
            style={littleSquareXs}
            className={
              "absolute left-4 top-6 aspect-square w-[40px] bg-[#A6D6FA] sm:top-8 md:left-0"
            }
          />
        )}
      </div>
    </>
  );
};

export default HeaderAnimations;
