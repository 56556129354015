import React from "react";
import JouleTypography from "../theme/JouleTypography";
import { l_joule } from "../../locales";
import { animated, useSpring } from "react-spring";

const EmpathySection = () => {
  const [style] = useSpring(() => {
    return {
      from: {
        background: "linear-gradient(90deg, #FFFFFF, #93C9FA)",
        backgroundClip: "text",
      },
      to: {
        background: "linear-gradient(90deg, #93C9FA, #FFFFFF)",
      },
      config: {
        duration: 2500,
      },
      loop: { reverse: true, delay: 0 },
    };
  });
  return (
    <div className="mt-10 max-w-full rounded-3xl bg-[#1F8FFF] px-4 pb-16 pt-8 sm:pb-20 sm:pt-12 md:mt-20 md:px-6 md:pb-24 md:pt-12">
      <div className="mx-auto mt-6 flex w-full max-w-[1100px] flex-col gap-8 sm:mt-16 sm:gap-12">
        <JouleTypography color="white" variant="body">
          {l_joule("empathy.title")}
        </JouleTypography>
        <animated.div style={style} className={"bg-clip-text"}>
          <JouleTypography className="text-transparent" variant="section-title">
            {l_joule("empathy.description")}
          </JouleTypography>
        </animated.div>
      </div>
    </div>
  );
};

export default EmpathySection;
