import { useMediaQuery, useTheme } from "@mui/material";
import { l_joule } from "../../locales";
import SquaresAnimation from "../Description/SquaresAnimation";
import JouleTypography from "../theme/JouleTypography";

const Testing = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div className="mt-10 flex w-full flex-col justify-between gap-10 sm:flex-row sm:gap-4 md:mt-20">
      <div className="flex-1">
        <JouleTypography variant="section-title" className="flex-1">
          {l_joule("testing.title")}
        </JouleTypography>
        <JouleTypography variant="body" className="mt-4 md:mt-6">
          {l_joule("testing.description")}
        </JouleTypography>
      </div>
      {isSm && (
        <div className="flex max-h-full w-[50%] flex-shrink-0 items-end justify-end">
          <SquaresAnimation />
        </div>
      )}
    </div>
  );
};

export default Testing;
