import Footer from "../../../components/shared/Footer/Footer";
import PrevNextCaseStudy from "../../../components/shared/PrevNextCaseStudy/PrevNextCaseStudy";
import Description from "./components/Description";
import EmpathySection from "./components/EmpathySection";
import GoalsSection from "./components/GoalsSection";
import Header from "./components/Header";
import FirstPhoneSection from "./components/PhoneSection/FirstPhoneSection";
import SecondPhoneSection from "./components/PhoneSection/SecondPhoneSection";
import PhotoSection from "./components/PhotoSection";
import QuestionSection from "./components/QuestionSection";
import TestingSection from "./components/TestingSection";
import ToneOfVoice from "./components/ToneOfVoice";

const Joule = () => {
  return (
    <div className="bg-[#F4F9FF]">
      <Header />
      <FirstPhoneSection />
      <Description />
      <QuestionSection />
      <PhotoSection />
      <GoalsSection />
      <SecondPhoneSection />
      <TestingSection />
      <ToneOfVoice />
      <EmpathySection />
      <PrevNextCaseStudy />
      <Footer />
    </div>
  );
};

export default Joule;
