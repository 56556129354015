import Testers from "./Testers";
import Testing from "./Testing";

const TestingSection = () => {
  return (
    <div className="mx-auto flex max-w-[1100px] flex-col gap-12 px-6 md:gap-24">
      <Testing />
      <Testers />
    </div>
  );
};

export default TestingSection;
