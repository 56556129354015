import React from "react";
import JouleTypography from "../theme/JouleTypography";
import { l_joule } from "../../locales";
import { animated, easings, useSpring } from "react-spring";
import { useMediaQuery, useTheme } from "@mui/material";

const TopSection = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));

  const [firstStyle] = useSpring(() => {
    return {
      from: { right: "0%" },
      to: { right: "35%" },
      config: {
        duration: 3000,
        easing: easings.easeInOutCirc,
      },
      loop: { reverse: true, delay: 1000 },
    };
  }, []);

  const [secondStyle] = useSpring(() => {
    return {
      from: { right: "35%" },
      to: { right: "0%" },
      config: {
        duration: 3000,

        easing: easings.easeInOutCirc,
      },
      loop: { reverse: true, delay: 1000 },
    };
  }, []);

  return (
    <div className="flex w-full justify-between gap-4">
      <JouleTypography variant="section-title" className="max-w-[980px] flex-1">
        {l_joule("description.topSection.part1")}
        <br />
        <JouleTypography variant="span" className="joule_underline">
          {l_joule("description.topSection.underlined")}
        </JouleTypography>
        {l_joule("description.topSection.part2")}
      </JouleTypography>
      {isMd && (
        <div className="w-[33%] flex-shrink-0">
          <div className="relative ml-auto h-full w-full max-w-[350px]">
            <animated.div
              className="absolute right-0 top-1/2 aspect-square w-[60%] -translate-y-1/2 rounded-full bg-[#d3e7ffcc]"
              style={firstStyle}
            />
            <animated.div
              style={secondStyle}
              className="absolute right-0 top-1/2 aspect-square w-[60%] -translate-y-1/2 rounded-full bg-[#73c4f999] mix-blend-multiply"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TopSection;
