import React from "react";
import JouleTypography from "../theme/JouleTypography";
import { l_joule } from "../../locales";
import LogoAnimation from "./LogoAnimation";

const ToneAndPerception = () => {
  return (
    <div className="mx-auto box-border flex w-full max-w-[900px] flex-col-reverse gap-6 p-6 sm:flex-row">
      <div className="basis-1/2">
        <JouleTypography variant="section-title" className="">
          {l_joule("toneOfVoice.titleTop")}
        </JouleTypography>
        <JouleTypography variant="body" className="mt-4 md:mt-6">
          {l_joule("toneOfVoice.descriptionTop")}
        </JouleTypography>
      </div>
      <div className="basis-1/2">
        <LogoAnimation />
      </div>
    </div>
  );
};

export default ToneAndPerception;
