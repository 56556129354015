import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

const FirstPhoneSection = () => {
  return (
    <div className="flex w-full items-center py-8">
      <div className="relative mx-auto flex min-h-[300px] w-full max-w-[1200px] md:min-h-[500px] lg:min-h-[600px]">
        <MabiParallax
          id="joule_phone"
          tweens={[
            {
              selector: "#phone_img_central",
              delay: 0,
              from: {
                transform: "translateX(-50%) scale(0.9)",
              },
              to: {
                transform: "translateX(-50%) scale(1)",
              },
            },
            {
              selector: "#phone_img_right",
              delay: 0.6,
              from: {
                transform: "translateX(-10%) rotate(0deg) scale(0.9)",
              },
              to: {
                transform: "translateX(20%) rotate(2deg) scale(1)",
              },
            },
            {
              selector: "#phone_img_left",
              delay: 0.6,
              from: {
                transform: "translateX(-90%) rotate(0deg) scale(0.9)",
              },
              to: {
                transform: "translateX(-110%) rotate(-2deg) scale(1)",
              },
            },
          ]}
        >
          <div
            id={"phone_img_left"}
            className="absolute left-1/2 top-0 z-10 aspect-[0.47] h-full min-h-full"
          >
            <StaticImage
              src={`../../images/phone_img_left.png`}
              alt=""
              objectFit="contain"
              className="max-h-full max-w-full drop-shadow-2xl"
            />
          </div>
          <div
            id={"phone_img_right"}
            className="absolute left-1/2 top-0 z-10 aspect-[0.47] h-full min-h-full"
          >
            <StaticImage
              src={`../../images/phone_img_right.png`}
              alt=""
              objectFit="contain"
              className="max-h-full max-w-full drop-shadow-2xl"
            />
          </div>
          <div
            id={"phone_img_central"}
            className="absolute left-1/2 top-0 z-20 aspect-[0.47] h-full min-h-full"
          >
            <StaticImage
              src={`../../images/phone_img_central.png`}
              alt=""
              objectFit="contain"
              className="max-h-full max-w-full drop-shadow-2xl"
            />
          </div>
        </MabiParallax>
      </div>
    </div>
  );
};

export default FirstPhoneSection;
